import Head from 'next/head'

import { useState } from 'react'

import { Chart, Stat, PageViewPicker } from '@onepager/ui'
import { supabase } from '@onepager/stdlib'
import numeral from 'numeral'
import { RiStickyNoteLine, RiLifebuoyLine, RiAddCircleLine } from 'react-icons/ri'
import LayoutMain from 'components/layout/LayoutMain'

export default function Web() {
  const user = supabase.auth.user()

  const [getOnepagerTotal, setOnepagerTotal] = useState(0)
  const [userPlan, setUserPlan] = useState('free')

  const [period, setPeriod] = useState('7d')
  const [dateRange, setDateRange] = useState({ dates: [] })

  return (
    <LayoutMain>
      <Head>
        <title>Dashboard - onepager.io</title>
      </Head>

      <div className="w-full px-5 mt-6">
        <div className="w-full">
          <div className="space-y-6">
            <div className="">
              <PageViewPicker
                period={period}
                dateRange={dateRange.dates}
                updatePeriod={value => {
                  setPeriod(value)
                }}
                updateDateRange={value => {
                  const hey = value
                  setDateRange({ dates: [] })
                  if (value.length > 1) {
                    let data = []
                    data.push(hey[0])
                    data.push(hey[1])
                    setPeriod('custom')
                    setDateRange({ dates: data })
                  }
                }}
                className="mb-4"
              />

              <div className="grid grid-cols-3 gap-6 text-slate-700 mb-4">
                <Stat
                  period={period}
                  dateRange={dateRange}
                  label="Total Views"
                  icon={<RiStickyNoteLine />}
                  user_id={user.id}
                />
                <div className="col-span-2 bg-white rounded border border-slate-200 items-center flex p-4">
                  <div className="w-full">
                    <div className="">
                      <div className="block font-bold text-xl">
                        {userPlan == 'free' && (
                          <span>
                            Free -{' '}
                            <a href="/upgrade" className="text-blue-500 underline font-bold">
                              Upgrade
                            </a>
                          </span>
                        )}
                        {userPlan == 'onepager_unlimited' && <span>Unlimited</span>}
                        {userPlan == 'onepager_unlimited_monthly' && <span>Unlimited Monthly</span>}
                        {userPlan == 'onepager_unlimited_annual' && <span>Unlimited Annual</span>}
                        {userPlan == 'onepager_monthly' && <span>Premium Monthly</span>}
                        {userPlan == 'onepager_annual' && <span>Premium Annual</span>}
                      </div>
                      <a href="/account" className="block font-medium text-slate-500">
                        {user.email}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <Chart type="timeseries" period={period} dateRange={dateRange.dates} user_id={user.id} />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-6 mt-6">
            <div className="bg-white border border-slate-200 rounded p-6">
              <h3 className="text-2xl font-bold mb-1">Version 2.0</h3>
              <p className="text-slate-500 text-lg mb-3">
                Thank you for using Onepager v2.0. We're very excited to announce that we've released a new
                version of Onepager. If you have feedback please let us know.
              </p>
              {/* <a
                href="https://onepager.releasenotes.io"
                className="rn-badge rounded relative w-full md:w-auto font-medium items-center group cursor-pointer transition shadow-sm hover:shadow-md text-sm py-2 px-4 bg-brand-dark-gray text-white"
              >
                <RiStickyNoteLine className="mr-1 inline-block" /> View Latest Updates
              </a> */}
            </div>

            <div className="bg-white border border-slate-200 rounded p-6">
              <h3 className="text-2xl font-bold mb-1 text-slate-900">Support Articles</h3>
              <p className="text-slate-500  mb-3">
                Check out our knowledge base filled with a variety of helpful articles. If you need help with
                a specific topic, we are happy to help. Just send us a message and we will get back to you.
              </p>
              <a
                href="https://support.onepager.io/support/home"
                target="_blank"
                rel="noreferrer"
                className="rounded relative w-full md:w-auto font-medium items-center group cursor-pointer transition shadow-sm hover:shadow-md text-sm py-2 px-4 bg-brand-dark-gray text-white"
              >
                <RiLifebuoyLine className="mr-1 inline-block" /> Support Desk
              </a>
            </div>
          </div>
        </div>
      </div>
    </LayoutMain>
  )
}
