import {
  LockOpenIcon,
  MailIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UserIcon,
  XIcon,
} from '@heroicons/react/outline'
import Link from 'next/link'
import Image from 'next/image'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import Breadcrumbs from 'nextjs-breadcrumbs'

const LayoutMain = ({ children, sideContent }) => {
  const router = useRouter()
  return (
    <div className="flex-1 min-w-0 flex flex-col overflow-hidden text-slate-700 bg-slate-100">
      <nav className="bg-white shadow z-10 relative">
        <div className="max-w-7xl mx-auto px-5">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <div className="h-20 flex space-x-3 items-center justify-center">
                  <Image
                    className="rounded-sm w-auto"
                    src="https://cdn.filestackcontent.com/jW9hY5L5RAWWpn2rGhw9"
                    alt="Onepager"
                    width="35"
                    height="35"
                  />
                  <span className="text-2xl font-bold ml-2 pensum-font">onepager</span>
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex space-x-4 font-medium">
                <Link href="/">
                  <span
                    className={classNames('px-4 py-1 rounded border shadow-xs cursor-pointer', {
                      'border-yellow-500 outline-yellow-500 outline':
                        (router.pathname === '/' && '/' === '/') ||
                        (router.pathname !== '/' && '/' !== '/' && router.pathname.includes('/')),
                    })}
                  >
                    Dashboard
                  </span>
                </Link>
                <Link href="/onepagers">
                  <span
                    className={classNames('px-4 py-1 rounded border shadow-xs cursor-pointer', {
                      'border-yellow-500 outline-yellow-500 outline':
                        (router.pathname === '/' && '/onepagers' === '/') ||
                        (router.pathname !== '/' &&
                          '/onepagers' !== '/' &&
                          router.pathname.includes('/onepagers')),
                    })}
                  >
                    Onepagers
                  </span>
                </Link>
                <Link href="/integrations">
                  <span
                    className={classNames('px-4 py-1 rounded border shadow-xs cursor-pointer', {
                      'border-yellow-500 outline-yellow-500 outline':
                        (router.pathname === '/' && '/integrations' === '/') ||
                        (router.pathname !== '/' &&
                          '/integrations' !== '/' &&
                          router.pathname.includes('/integrations')),
                    })}
                  >
                    Integrations
                  </span>
                </Link>
                <Link href="/account">
                  <span
                    className={classNames('px-4 py-1 rounded border shadow-xs cursor-pointer', {
                      'border-yellow-500 outline-yellow-500 outline':
                        (router.pathname === '/' && '/account' === '/') ||
                        (router.pathname !== '/' &&
                          '/account' !== '/' &&
                          router.pathname.includes('/account')),
                    })}
                  >
                    Account
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <Breadcrumbs
        containerClassName="bg-slate-50 py-2 font-medium text-xs text-slate-400 shadow z-0"
        listClassName="max-w-7xl mx-auto px-5 flex space-x-3"
        inactiveItemClassName="text-sm text-slate-400"
        activeItemClassName="text-sm text-slate-700"
        rootLabel="Home"
        labelsToUppercase={true}
      />

      <main className="w-full max-w-7xl mx-auto">{children}</main>

      <footer className="border-t h-full mt-12 bg-white shadow">
        <div className="py-16 border-t-2 border-slate-50">
          <div className="max-w-7xl mx-auto px-5 grid grid-cols-4 gap-12">
            <div className="col-span-2">
              <div className="flex items-center">
                <Image
                  className=" w-auto"
                  src="https://cdn.filestackcontent.com/jW9hY5L5RAWWpn2rGhw9"
                  alt="Onepager"
                  width="35"
                  height="35"
                />
                <span className="text-2xl font-bold ml-2">onepager</span>
              </div>
              <p className="text-xs mt-6 text-slate-400">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum dolor perferendis labore hic
                ullam dignissimos reprehenderit unde repellat vel obcaecati, quis expedita sed perspiciatis
                vitae magni recusandae suscipit beatae possimus?
              </p>
              <p className="text-xs mt-6 text-slate-400">Copyright 2022 &copy; Onepager.io</p>
            </div>
            <div>
              <h2 className="text-slate-700 font-bold mb-1">Navigation</h2>
              <ul className="text-sm text-slate-600">
                <li>
                  <Link href="/">Dashboard</Link>
                </li>
                <li>
                  <Link href="/onepagers">Onepagers</Link>
                </li>
                <li>
                  <Link href="/integrations">Integrations</Link>
                </li>
                <li>
                  <Link href="/account">Account</Link>
                </li>
                <li className="border-t pt-2 mt-2">
                  <Link href="/">Logout</Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="text-slate-700 font-bold mb-1">Resources</h2>
              <ul className="text-sm text-slate-600">
                <li>
                  <Link href="/">Submit Support Ticket</Link>
                </li>
                <li>
                  <Link href="/">Support Docs</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default LayoutMain
